import { cn } from '@repo/ui/lib/utils'
import type { ComponentProps } from 'react'
import { Link } from 'react-router'

export const TextLink = ({
  className,
  ...props
}: ComponentProps<typeof Link>) => {
  return (
    <Link
      {...props}
      className={cn('underline-offset-2 hover:underline', className)}
    />
  )
}
